export enum Route {
  b2bAdminAboutUs = '/b2b-admin/about-us',
  b2bAdminGraphsReport = '/b2b-admin/graphs-report',
  b2bAdminMonthlyReport = '/b2b-admin/monthly-report',
  b2bAdminAiSummary = '/b2b-admin/ai-summary',
  b2bAdminVisualContent = '/b2b-admin/visual-content',
  companyProfile = '/company-profile',
  companyList = '/company-list',
  companyGroup = '/company-group',
  interestingProfileGallery = '/interesting-profile-gallery',
  introduction = '/introduction',
  jobDescriptionList = '/job-description-list',
  jobDescriptionDetail = '/job-description-detail',
  jobApplication = '/job-application',
  jobApplicationSent = '/job-application-sent',
  loginWithPassword = '/login',
  profileMatching = '/matching',
  profileMatchingResult = '/matching/result',
  registration = '/registration',
  resetPassword = '/reset-password',
  setPassword = '/set-password',
  informationForCompanies = '/information-for-companies',
  informationForCompaniesHooray = '/thank-you-for-your-interest',
  userAccountSubscriptions = '/user-account/subscriptions',
}
